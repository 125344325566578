<template>
  <div class="gamemodal mobile">
    <button class="gamemodal-close-btn" type="button" @click="closeGameWelcome">
      <i class="fas fa-times"></i>
    </button>
    <div class="top_banner">
      <img src="@/assets/img/core/Minigame_list_header.png" />
    </div>
    <div class="main_minigame  modal-body">
      <div class="right_cont">
        <div class="tit">MINI GAME</div>
        <div class="gameplay_list_wrap">
          <ul class="gameplay_list">
            <template v-for="item in vendorList" :key="item.procId">
              <li @click="getGameUrl(item.procId)">
                <div class="gameplay">
                  <div class="img">
                    <img :src="loadBackground(item.procId)" alt="" />
                  </div>
                  <!-- <div class="over"><img src="@/assets/img/winner_pc/play_over.png" alt="" /></div> -->
                </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="@/styles/winner_pc/game.css"></style>
<script>
import { isMobile } from '@/libs/utils'
import { minigameDisplaySize } from '@/libs/constants'
import { getMiniGameUrl } from '@/api/game'
import store from '@/store'
import { mapState } from 'vuex'

export default {
  name: 'mini',
  components: {},
  data() {
    return {
      displayInfo: minigameDisplaySize,
      gameList: []
    }
  },
  computed: {
    ...mapState([
      'productList'
    ]),
    vendorList() {
      return this.productList.filter((item) => item.groupCode === 'minigame')
    }
  },
  methods: {
    loadBackground(procId) {
      // console.log(procId)
      try {
        return require(`@/assets/img/core/Minigame_list_${procId}.jpg`)
      } catch (e) {
        // console.log(e)
        return ''
      }
    },
    closeGameWelcome(e) {
      // console.log(e)
      store.dispatch('storeGameComponent', '')
    },
    async getGameUrl(game) {
      const device = ((device) => {
        let _device = '0'
        if (device) {
          _device = '1'
        }
        return _device
      })(isMobile())
      const reqModel = {
        siteName: this.siteName,
        id: this.userId,
        isMobile: device,
        lang: this.$i18n.locale
      }
      const windowSize = {
        coin5: 'width=1170px,height=920px',
        coin3: 'width=1170px,height=920px',
        pbg: 'width=1140px,height=880px',
        default: 'width=1140px,height=855px'
      }
      const gameWindow = window.open('/preload', '_blank', windowSize[game] || windowSize.default)
      try {
        this.emitter.emit('Loading', true)
        const response = await getMiniGameUrl(game, reqModel)
        const { data } = response.data
        if (data.gameurl) {
          gameWindow.location.href = data.gameurl
          this.emitter.emit('Loading', false)
        }
      } catch (e) {
        gameWindow.close()
        console.error(e)
      }
      this.emitter.emit('Loading', false)
    }
  },
  async created() {
    // await this._getMiniGameList()
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/game.scss';

.main_minigame .gameplay_list_wrap {
  padding: 0;
}

.main_minigame .gameplay_list {
  min-width: unset;
}
</style>

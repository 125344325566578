<template>
  <div class="gamemodal">
    <button class="gamemodal-close-btn" type="button" @click="closeGameWelcome">
      <i class="fas fa-times"></i>
    </button>
    <div class="top_banner">
      <img src="@/assets/img/core/Casino_list_header.png" />
    </div>
    <div class="casino">
      <!-- <div class="ani_img1">
        <img src="@/assets/img/winner_pc/main/live_casino_1.png" />
      </div> -->
      <!-- <div class="ani_img3"><img src="@/assets/img/winner_pc/img_m_casino_3.png" alt="" /></div> -->
      <div class="right_cont">
        <div class="tit">
          CASINO
        </div>
        <div class="game-list-wrap">
          <ul class="game_list">
            <template v-if="vendorList && vendorList.length !== 0">
              <template v-for="(item, index) in vendorList" :key="item.procId">
                <li v-if="item.groupCode === 'casino'">
                  <div class="gamebox" @click="getGameUrl(`${item.procId}`, `${item.lobbySymbol}`)">
                    <div class="img">
                      <img :src="loadBackground(index+1)" alt="" />
                    </div>
                    <div class="logo">
                      <img :src="loadLogoImg(item.procId)" alt="" />
                    </div>
                    <div class="txt">{{ $t(`front.gameCode.casino.${item.procId}`) }}</div>
                  </div>
                </li>
              </template>
              <template v-if="vendorList.length % 4 !== 0">
                <li class="empty-flex" v-for="loop in 4-(vendorList.length % 4)" :key="loop"></li>
              </template>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getGameList, getGameUrlCasino } from '@/api/game'
import store from '@/store'
import { isMobile } from '@/libs/utils'
import { mapState } from 'vuex'

export default {
  name: 'Casino',
  components: {},
  data() {
    return {
      gameVendor: '201'
    }
  },
  computed: {
    ...mapState([
      'productList'
    ]),
    vendorList() {
      return this.productList.filter(item => item.groupCode === 'casino')
    }
  },
  methods: {
    closeGameWelcome(e) {
      store.dispatch('storeGameComponent', '')
    },
    async getCasinoList(vendorKey) {
      const params = {
        vendorKey: vendorKey,
        gameType: 'casino'
      }
      try {
        const response = await getGameList(params)
        console.log(response)
      } catch (e) {
        console.log(e)
      }
    },
    async getGameUrl(item, gameKey) {
      const gameWindow = window.open('/preload', '_blank', 'width=1280,height=720')
      this.emitter.emit('Loading', false)
      const params = {
        gameGroup: 'casino',
        vendorKey: item,
        isMobile: isMobile() ? '1' : '0',
        gameKey: gameKey,
        lang: this.$i18n.locale
      }
      try {
        for (const key in params) {
          if (!params[key]) delete params[key]
        }
        const response = await getGameUrlCasino(params)
        const { resultCode, resultMessage, data } = response.data
        if (resultCode === '200' && resultMessage === 'SUCCESS') {
          gameWindow.location.href = data.gameUrl
          this.emitter.emit('Loading', false)
        } else {
          gameWindow.close()
          throw new Error(this.$t(`api.${resultCode}`))
        }
      } catch (e) {
        gameWindow.close()
        alert(e.message)
        console.log(e)
        // console.log(this.$router)
      }
      this.emitter.emit('Loading', false)
    },
    loadLogoImg(procId) {
      // console.log(procId)
      try {
        return require(`@/assets/img/logo/${procId}-1.png`)
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    loadBackground(index) {
      // console.log(procId)
      try {
        return require(`@/assets/img/core/Casino_list_${index}.jpg`)
      } catch (e) {
        // console.log(e)
        return ''
      }
    }
  }
}
</script>
<style src="@/styles/winner_pc/game.css"></style>
<style scoped lang="scss" src="@/styles/game.scss"></style>
